import React, { useState, useEffect, useCallback } from 'react';
import { Search, Camera, Mic } from 'lucide-react';
import { Button, Stack, Alert } from '@material-ui/core';
import BarcodeScannerComponent from "react-qr-barcode-scanner";

import { buscarProducto } from '../../services/buscadorPrecios';

import './styles.css';

import imagenBuscar from '../../assets/images/buscador-precios/Buscar.svg';
import imagenBuscando from '../../assets/images/buscador-precios/Buscando.svg';

const SearchBar = ({ onResults, setNoEncontrado }) => {
    const [query, setQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isListening, setIsListening] = useState(false);
    const [voiceError, setVoiceError] = useState(null);
    const [codigoBarras, setCodigoBarras] = useState('No result');
    const [openCodigoBarras, setOpenCodigoBarras] = useState(false);

    useEffect(() => {
        return () => {
            // Asegúrate de realizar la limpieza necesaria aquí
            // Por ejemplo, detener el escáner si es necesario
            if (BarcodeScannerComponent.destroy) {
                BarcodeScannerComponent.destroy();
            }
        };
    }, []);

    // Escuchar cuando el codigo de barras se lea
    useEffect(() => {
        buscarCodigoBarras();
    }, [codigoBarras])

    const buscarCodigoBarras = async () => {
        if (codigoBarras !== 'No result') {
            setIsLoading(true);
            await buscarProducto({ "codigo_barras": codigoBarras }).then(onResults);

            await buscarProducto({ "codigo_barras": codigoBarras }).then((data) => {
                onResults(data);

                if (data.length === 0) {
                    setNoEncontrado(true);
                } else {
                    setNoEncontrado(false);
                }

                setQuery("");
                setCodigoBarras("No result");
            });
            setIsLoading(false);
            setOpenCodigoBarras(false);
        }
    }


    // Función para iniciar el reconocimiento de voz
    const startVoiceRecognition = useCallback(() => {
        if (!('webkitSpeechRecognition' in window)) {
            setVoiceError('La búsqueda por voz no está soportada en este navegador');
            return;
        }

        const recognition = new (window).webkitSpeechRecognition();
        recognition.lang = 'es-MX';
        recognition.continuous = false;
        recognition.interimResults = false;

        recognition.onstart = () => {
            setIsListening(true);
            setVoiceError(null);
        };

        recognition.onresult = async (event) => {
            const text = event.results[0][0].transcript;
            setQuery(text);
            setIsListening(false);
            // Iniciar búsqueda automáticamente
            setIsLoading(true);
            if (text.trim()) {
                await buscarProducto({ "query": text }).then((data) => {
                    console.log(data);
                    onResults(data);
    
                    if (data.length === 0) {
                        setNoEncontrado(true);
                    } else {
                        setNoEncontrado(false);
                    }
    
                    setQuery("");
                });
            }
            setIsLoading(false);
        };

        recognition.onerror = (event) => {
            setVoiceError('Error en el reconocimiento de voz. Intenta de nuevo.');
            setIsListening(false);
        };

        recognition.onend = () => {
            setIsListening(false);
        };

        try {
            recognition.start();
        } catch (error) {
            setVoiceError('Error al iniciar el reconocimiento de voz');
            setIsListening(false);
        }
    }, []);

    const handleSearch = async () => {
        setIsLoading(true);
        if (query.trim()) {
            await buscarProducto({ "query": query }).then((data) => {
                onResults(data);

                if (data.length === 0) {
                    setNoEncontrado(true);
                } else {
                    setNoEncontrado(false);
                }

                setQuery("");
            });
        }
        setIsLoading(false);
    };

    return (
        <div className="p-4 bg-white border-b">
            {voiceError && 
                <div className="bg-red-100 text-red-600 p-4 mb-4">
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity="error" onClose={() => setVoiceError(null)}>{voiceError}</Alert>
                    </Stack>
                </div>
            }
            
            <div className="flex items-center gap-4 mb-4">
                <button className="flex items-center text-orange-500 font-semibold">
                    <Search size={20} className="mr-2" />
                    Busca tus Productos aquí
                </button>
            </div>
            <div className="flex gap-2 p-1">
                <div className="flex relative flex-1">
                    <input
                        type="text"
                        placeholder="Buscar producto..."
                        className="w-full p-3 border rounded-lg pr-24"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                </div>
                <button
                    onClick={handleSearch}
                    disabled={isLoading}
                    className="boton"
                >
                    <img src={isLoading ? imagenBuscando : imagenBuscar} alt="Buscar" />
                    {/* {isLoading ? 'Buscando...' : 'Buscar'} */}
                </button>
            </div>
            <div className="flex gap-2 p-1">
                <div className="flex relative flex-1 justify-center">
                    <Button
                        variant="text"
                        size="small"
                        className={`p-0 ${isListening ? 'text-blue-500' : 'text-gray-400'}`}
                        onClick={startVoiceRecognition}
                        disabled={isListening}
                    >
                        <Mic className="h-5 w-5" />
                    </Button>
                    <Button
                        variant="text"
                        size="small"
                        // className={`p-0 ${isListening ? 'text-blue-500' : 'text-gray-400'}`}
                        onClick={() => setOpenCodigoBarras(!openCodigoBarras)}
                    >
                        <Camera className="h-5 w-5" />
                    </Button>
                </div>
            </div>
            <div className="flex gap-2">
                <div className="flex relative flex-1 justify-center">
                    <p>Habla o escanea un código de barras para buscar.</p>
                </div>
            </div>
            {
                openCodigoBarras && (
                    <div className="flex justify-center">
                        <div>
                            <h1>Escáner de Códigos de Barras</h1>
                            <BarcodeScannerComponent
                                width={500}
                                height={500}
                                onUpdate={(err, result) => {
                                    if (result) setCodigoBarras(result.text);
                                    else setCodigoBarras('No result');
                                }}
                            />
                            <p>{codigoBarras}</p>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default SearchBar;