import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
    Search, Camera, Mic, ShoppingCart, User,
    Home, Store, FileText, Menu, MapPin, List,
    TrendingDown, Navigation, ArrowDownCircle
} from 'lucide-react';
import { Alert, AlertTitle, Stack } from '@mui/material'

import SearchBar from './SearchBar';
import ProductCard from './ProductCard';
import './styles.css';

import imagenHeader from '../../assets/images/buscador-precios/Header.svg';

const queryClient = new QueryClient();

// Mock data mejorado
const mockStores = [
    {
        name: "El Zorro Abarrotero",
        price: 18.00,
        distance: 2.71,
        isOpen: true,
        lastUpdate: "hace 5 min",
        stock: 45,
        lat: 19.4326,
        lng: -99.1332
    },
    {
        name: "Super 1",
        price: 19.00,
        distance: 1.00,
        isOpen: true,
        lastUpdate: "hace 2 min",
        stock: 32,
        lat: 19.4346,
        lng: -99.1342
    },
    {
        name: "Scorpion",
        price: 19.00,
        distance: 2.75,
        isOpen: false,
        lastUpdate: "hace 15 min",
        stock: 28,
        lat: 19.4336,
        lng: -99.1312
    },
    {
        name: "Tiendas 3B",
        price: 20.00,
        distance: 1.10,
        isOpen: true,
        lastUpdate: "hace 8 min",
        stock: 15,
        lat: 19.4316,
        lng: -99.1322
    }
];

export default function BuscadorPrecios() {
    const [searchQuery, setSearchQuery] = useState('');
    const [showMap, setShowMap] = useState(false);
    const [selectedStore, setSelectedStore] = useState(null);
    const [results, setResults] = useState([]);
    const [noEncontrado, setNoEncontrado] = useState(false);

    // Estadísticas calculadas
    const openStores = mockStores.filter(s => s.isOpen).length;
    const bestPrice = Math.min(...mockStores.map(s => s.price));
    const nearestStore = Math.min(...mockStores.map(s => s.distance));

    return (
        <QueryClientProvider client={queryClient}>
            <div className="flex flex-col h-screen bg-gray-50">
                {/* Header y búsqueda mantienen el mismo diseño */}
                <header className="flex items-center justify-center">
                    <img src={imagenHeader} alt="Buscador de Precios" />
                    {/* <Menu size={24} /> */}
                    {/* <span className="text-xl font-bold">Scorpion</span> */}
                    {/* <div className="flex items-center gap-4">
                        <div className="flex items-center gap-2">
                            <div className="text-right">
                                <span className="text-xs">INICIAR</span>
                                <br />
                                <span className="text-xs">SESIÓN</span>
                            </div>
                            <User size={24} />
                        </div>
                        <div className="relative">
                            <ShoppingCart size={24} />
                            <span className="absolute -top-2 -right-2 bg-orange-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                                0
                            </span>
                        </div>
                    </div> */}
                </header>

                {/* Search Section */}
                <SearchBar onResults={setResults} setNoEncontrado={setNoEncontrado} />

                {/* View Toggle con información adicional */}
                {/* <div className="p-2 bg-white border-b">
                    <div className="flex justify-center gap-2">
                        <button
                            onClick={() => setShowMap(false)}
                            className={`relative flex flex-col items-center p-3 rounded-lg min-w-32 ${!showMap ? 'bg-blue-800 text-white' : 'bg-gray-100 text-gray-600'
                                }`}
                        >
                            <div className="flex items-center gap-2 mb-1">
                                <List size={20} />
                                <span>Lista</span>
                            </div>
                            <div className="text-xs flex items-center gap-2">
                                <span className="flex items-center">
                                    <Store size={14} className="mr-1" />
                                    {openStores} abiertas
                                </span>
                                <span className="flex items-center text-green-500">
                                    <TrendingDown size={14} className="mr-1" />
                                    ${bestPrice}
                                </span>
                            </div>
                        </button>

                        <button
                            onClick={() => setShowMap(true)}
                            className={`relative flex flex-col items-center p-3 rounded-lg min-w-32 ${showMap ? 'bg-blue-800 text-white' : 'bg-gray-100 text-gray-600'
                                }`}
                        >
                            <div className="flex items-center gap-2 mb-1">
                                <MapPin size={20} />
                                <span>Mapa</span>
                            </div>
                            <div className="text-xs flex items-center gap-2">
                                <span className="flex items-center">
                                    <Navigation size={14} className="mr-1" />
                                    {nearestStore}km cerca
                                </span>
                                <span className="flex items-center">
                                    <ArrowDownCircle size={14} className="mr-1" />
                                    {mockStores.length} total
                                </span>
                            </div>
                        </button>
                    </div>
                </div> */}

                {/* Product Display */}
                <div className="mt-6">
                    {results.map((product) => (
                        <div key={product.id}>
                            <ProductCard key={product.id} product={product} />
                        </div>
                    ))}
                </div>

                {/* No Results */}
                {noEncontrado && (
                    <div className="text-red-600 p-4 mb-4">
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="info">
                                <AlertTitle>¡Producto no Encontrado!</AlertTitle>
                                Pronto lo agregaremos a la lista.
                            </Alert>
                        </Stack>
                    </div>
                )}

                {/* {noEncontrado && (
                    <div className="justify-center items-center flex">
                        <div className='card cursor-pointer border border-gray-200 rounded-lg shadow-md transition-transform duration-300 ease-in-out p-4 flex gap-4'>
                            <div className="justify-center items-center flex">
                                <h3 className="font-bold text-lg">¡Producto no Encontrado!</h3>
                            </div>
                            <div className="justify-center items-center flex">
                                <p className="text-gray-600">Pronto lo agregaremos a la lista.</p>
                            </div>
                        </div>
                    </div>
                )} */}

                {/* En react como puedo hacer que al dar click en una Card se hace un zoom */}

                {/* Content Area */}
                {/* <div className="flex-1 overflow-auto p-4 bg-white border-b">
                    {showMap ? (
                        // Vista de Mapa con información adicional
                        <div className="relative h-full">
                            <div className="absolute top-4 left-4 z-10 bg-white rounded-lg shadow-lg p-3">
                                <div className="text-sm">
                                    <div className="font-semibold mb-2">Resumen de tiendas</div>
                                    <div className="space-y-1">
                                        <div className="flex items-center gap-2">
                                            <Store size={14} className="text-green-500" />
                                            <span>{openStores} tiendas abiertas</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <Navigation size={14} className="text-blue-500" />
                                            <span>Tienda más cercana a {nearestStore}km</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <TrendingDown size={14} className="text-green-500" />
                                            <span>Mejor precio: ${bestPrice}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="h-full bg-gray-100 flex items-center justify-center">
                                <img
                                    src="/api/placeholder/600/400"
                                    alt="Mapa"
                                    className="max-w-full h-auto rounded-lg shadow-lg"
                                />
                            </div>

                            {selectedStore && (
                                <div className="absolute bottom-4 left-4 right-4 bg-white p-4 rounded-lg shadow-lg">
                                    <div className="flex justify-between items-start">
                                        <div>
                                            <div className="flex items-center gap-2">
                                                <h3 className="font-semibold">{selectedStore.name}</h3>
                                                <span className={`text-xs px-2 py-1 rounded ${selectedStore.isOpen ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
                                                    }`}>
                                                    {selectedStore.isOpen ? 'Abierto' : 'Cerrado'}
                                                </span>
                                            </div>
                                            <p className="text-sm text-gray-600">
                                                A {selectedStore.distance} km • Actualizado {selectedStore.lastUpdate}
                                            </p>
                                            <p className="text-lg font-bold text-green-600 mt-1">
                                                ${selectedStore.price.toFixed(2)}
                                            </p>
                                            <p className="text-sm text-gray-600">
                                                Stock disponible: {selectedStore.stock} unidades
                                            </p>
                                        </div>
                                        <button className="bg-blue-800 text-white px-4 py-2 rounded-lg">
                                            Cómo llegar
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        // Vista de Lista con información adicional
                        <div className="p-4">
                            <div className="bg-white rounded-lg p-4 shadow-sm">
                                <div className="flex justify-between items-center mb-4">
                                    <h3 className="font-semibold">DISPONIBILIDAD</h3>
                                    <div className="text-sm text-gray-600">
                                        Actualizado hace 2 min
                                    </div>
                                </div>

                                <div className="grid grid-cols-3 text-sm font-semibold mb-2">
                                    <span>TIENDA</span>
                                    <span>PRECIO. U</span>
                                    <span>DIST. (KM)</span>
                                </div>

                                {mockStores.map((store, index) => (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            setSelectedStore(store);
                                            setShowMap(true);
                                        }}
                                        className="grid grid-cols-3 text-sm py-3 border-b cursor-pointer hover:bg-gray-50"
                                    >
                                        <div className="flex flex-col">
                                            <span className="font-medium">{store.name}</span>
                                            <div className="flex items-center gap-1 mt-1">
                                                <span className={`text-xs px-2 py-0.5 rounded ${store.isOpen ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
                                                    }`}>
                                                    {store.isOpen ? 'Abierto' : 'Cerrado'}
                                                </span>
                                                <span className="text-xs text-gray-500">
                                                    • {store.stock} en stock
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex items-center">
                                            <span className={store.price === bestPrice ? "text-green-600 font-semibold" : ""}>
                                                ${store.price.toFixed(2)}
                                            </span>
                                        </div>
                                        <div className="flex items-center">
                                            <span>{store.distance.toFixed(2)} km</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div> */}

                {/* Bottom Navigation */}
                {/* <nav className="fixed bottom-0 left-0 right-0 bg-white border-t p-2">
                    <div className="flex justify-around py-2">
                        <button className="flex flex-col items-center text-gray-500">
                            <Home size={24} />
                            <span className="text-xs">Inicio</span>
                        </button>
                        <button className="flex flex-col items-center text-gray-500">
                            <User size={24} />
                            <span className="text-xs">Perfil</span>
                        </button>
                        <button className="flex flex-col items-center text-orange-500">
                            <ShoppingCart size={24} />
                            <span className="text-xs">Comprar</span>
                        </button>
                        <button className="flex flex-col items-center text-gray-500">
                            <Store size={24} />
                            <span className="text-xs">Tiendas</span>
                        </button>
                        <button className="flex flex-col items-center text-gray-500">
                            <FileText size={24} />
                            <span className="text-xs">Reporta</span>
                        </button>
                    </div>
                </nav> */}
            </div>
        </QueryClientProvider>
    );
}