import React, {useState} from 'react';
import './styles.css';

const { REACT_APP_API_URL_IMAGES, REACT_APP_API_URL_IMAGES_PRODUCTOS_BP } = process.env;

const ProductCard = ({ product }) => {
    const [isZoomed, setIsZoomed] = useState(false);

    console.log(`${REACT_APP_API_URL_IMAGES_PRODUCTOS_BP}${product.imagen}`);

    return (
        <div className="justify-center items-center flex">
            <div
                className={`card ${isZoomed ? 'zoomed' : ''} cursor-pointer border border-gray-200 rounded-lg shadow-md transition-transform duration-300 ease-in-out`}
                onClick={() => setIsZoomed(!isZoomed)}
            >
                <div className="p-4 flex gap-2">
                    <div className="flex gap-4">
                        <img
                            src={product.imagen ? `${REACT_APP_API_URL_IMAGES_PRODUCTOS_BP}${product.imagen}` : `${REACT_APP_API_URL_IMAGES}public/producto_bp.jpg`}
                            alt={product.descripcion}
                            className="w-24 h-24 object-cover rounded"
                        />
                        <div>
                            <h3 className="font-bold text-lg">{product.descripcion}</h3>
                            <p className="text-gray-600">{product.marca}</p>
                            <p className="text-gray-600">{product.cod_barras}</p>
                            <div className='flex gap-2'>
                                <div className="mt-2">
                                    <p className="text-sm font-bold" style={{ color: "#008D36", whiteSpace: 'nowrap' }}>${product.precio_sug}</p>
                                    <p className="text-sm font-bold" style={{ color: "#1D7188", whiteSpace: 'nowrap' }}>${product.precio_pza}</p>
                                    <p className="text-sm font-bold" style={{ color: "#F39200", whiteSpace: 'nowrap' }}>${((product.precio_pza * product.margen) / 100).toFixed(2)}</p>
                                </div>
                                <div class="vertical-line" />
                                <div className="mt-2">
                                    <p className="text-sm font-bold" style={{ color: "#008D36", whiteSpace: 'nowrap' }}>Precio Público</p>
                                    <p className="text-sm font-bold" style={{ color: "#1D7188", whiteSpace: 'nowrap' }}>Precio Scorpion</p>
                                    <p className="text-sm font-bold" style={{ color: "#F39200", whiteSpace: 'nowrap' }}>Ganancia</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;